/* Remove fieldset defaults and remove legend */

.boxel-toggle-button-group__fieldset {
  border: 0;
  padding: 0.01em 0 0;
  min-width: 0;
}

.boxel-toggle-button-group__fieldset-legend {
  opacity: 0;
  position: absolute;
  left: -9999px;
  max-width: 1px;
  max-height: 1px;
  white-space: nowrap;
}

.boxel-toggle-button-group__fieldset-container {
  display: flex;
  flex-wrap: wrap;
}

.boxel-toggle-button-group-option {
  --padding: var(--boxel-toggle-button-group-option-padding, var(--boxel-sp));

  position: relative;
  display: block;
  max-width: 100%;

  /* Leave space for the checkmark */
  padding:
    var(--padding)
    calc(
      var(--padding) +
      var(--boxel-sp-xs) * 2
    )
    var(--padding)
    var(--padding);
  transition: box-shadow var(--boxel-transition);
  background-color: var(--boxel-light);
  box-shadow: none;
  border: 1px solid var(--boxel-purple-300);
}

/* Shift all but the first option left to hide double-borders: https://codepen.io/torez233/pen/rNmYbKQ */

.boxel-toggle-button-group-option + .boxel-toggle-button-group-option {
  margin-left: -2px;
}

/* Add curves on the outside corners of the first and last options */

.boxel-toggle-button-group-option:first-child {
  border-top-left-radius: var(--boxel-border-radius);
  border-bottom-left-radius: var(--boxel-border-radius);
}

.boxel-toggle-button-group-option:last-child {
  border-top-right-radius: var(--boxel-border-radius);
  border-bottom-right-radius: var(--boxel-border-radius);
}

.boxel-toggle-button-group-option:hover:not(.boxel-toggle-button-group-option--disabled) {
  cursor: pointer;
}

.boxel-toggle-button-group-option:focus-within:not(.boxel-toggle-button-group-option--disabled) {
  outline: 1px solid var(--boxel-highlight);
  z-index: 100;
}

.boxel-toggle-button-group-option--disabled > * {
  opacity: 0.5;
}

/* Hide radio button circle: https://css-tricks.com/customise-radio-buttons-without-compromising-accessibility/ */
.boxel-toggle-button-group-option__input {
  appearance: none;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
  position: absolute;
  top: 0;
  left: 0;
  clip-path: polygon(0 0);
  width: 1px;
  height: 1px;
}

.boxel-toggle-button-group-option--checked {
  background-color: var(--boxel-light-200);
  background-image: url("/@cardstack/boxel/images/icons/checkbox-primary-checked.svg");
  background-position: top var(--boxel-sp-xs) right var(--boxel-sp-xs);
  background-repeat: no-repeat;
  background-size: var(--boxel-sp);
  box-shadow: inset 0 4px 3px -2px var(--boxel-light-500);
}

.boxel-toggle-button-group-option--checked:last-child {
  box-shadow: inset -3px 4px 3px -2px var(--boxel-light-500);
}
