.boxel-input {
  --boxel-input-height: var(--boxel-form-control-height);

  width: 100%;
  min-height: var(--boxel-input-height);
  padding: var(--boxel-sp-xxs) 0 var(--boxel-sp-xxs) var(--boxel-sp);
  border: 1px solid var(--boxel-form-control-border-color);
  border-radius: var(--boxel-form-control-border-radius);
  font: var(--boxel-font);
  letter-spacing: var(--boxel-lsp-sm);
  transition: border-color var(--boxel-transition);
}

.boxel-input:disabled {
  background-color: var(--boxel-light);
  border-color: var(--boxel-purple-300);
  color: rgb(0 0 0 / 50%);
  opacity: 0.5;
}

.boxel-input:hover:not(:disabled) {
  border-color: var(--boxel-dark);
}

.boxel-input--invalid {
  border-color: var(--boxel-error-100);
  box-shadow: 0 0 0 1px var(--boxel-error-100);
}

.boxel-input--invalid:focus {
  outline: 1px solid transparent;  /* Make sure that we make the invalid state visible */
  box-shadow: 0 0 0 1.5px var(--boxel-error-100);
}

.boxel-input--invalid:hover:not(:disabled) {
  border-color: var(--boxel-error-100);
}

.boxel-input__optional {
  grid-row: 1;
  grid-column: 1 / -1;
  margin-bottom: var(--boxel-sp-xxxs);
  color: rgb(0 0 0 / 75%);
  font: var(--boxel-font-sm);
  font-style: oblique;
  letter-spacing: var(--boxel-lsp);
  text-align: right;
}

.boxel-input__error-message {
  grid-column: 2;
  margin-top: var(--boxel-sp-xs);
  margin-left: var(--boxel-sp-xs);
  color: var(--boxel-error-100);
  font: var(--boxel-font-sm);
  letter-spacing: var(--boxel-lsp);
}

.boxel-input__helper-text {
  grid-column: 2;
  margin-top: var(--boxel-sp-xs);
  margin-left: var(--boxel-sp-xs);
  color: rgb(0 0 0 / 75%);
  font: var(--boxel-font-sm);
  letter-spacing: var(--boxel-lsp);
}

.boxel-input:disabled ~ .boxel-input__error-message,
.boxel-input:disabled ~ .boxel-input__helper-text {
  display: none;
}
