.boxel-field {
  --boxel-field-label-align: normal;

  display: grid;
  gap: var(--boxel-sp-xs) 0;
}

.boxel-field--vertical {
  grid-template-rows: auto 1fr;
}

.boxel-field--centered-display {
  justify-items: center;
}

.boxel-field--centered-display > *:last-child {
  order: -1;
}

.boxel-field--horizontal {
  grid-template-columns: var(--boxel-field-label-size, minmax(4rem, 25%)) 1fr;
  gap: 0 var(--boxel-sp-lg);
}

.boxel-field--small-label {
  --boxel-field-label-size: minmax(4rem, 10%);
}

.boxel-field__label {
  color: var(--boxel-purple-400);
  font: 600 var(--boxel-font-xs);
  letter-spacing: var(--boxel-lsp-xl);
  text-transform: uppercase;
  display: flex;
  align-items: var(--boxel-field-label-align);
}

.boxel-field--with-icon {
  display: flex;
}

.boxel-field__icon {
  width: var(--boxel-icon-sm);
  height: var(--boxel-icon-sm);
  margin-right: var(--boxel-sp-xxs);
}

.boxel-field__yield--with-icon {
  width: 100%;
}
