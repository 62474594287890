.boxel-input-group {
  --boxel-input-group-padding-x: var(--boxel-sp-sm);
  --boxel-input-group-padding-y: var(--boxel-sp-xxs);
  --boxel-input-group-border-color: var(--boxel-form-control-border-color);
  --boxel-input-group-border-radius: var(--boxel-form-control-border-radius);
  --boxel-input-group-interior-border-width: 0;
  --boxel-input-group-height:
    calc(
      (var(--boxel-ratio) * var(--boxel-font-size)) + (2 * var(--boxel-input-group-padding-y)) + 2px
    );

  font-family: var(--boxel-font-family);
  font-size: var(--boxel-font-size);
  line-height: var(--boxel-ratio);
  letter-spacing: var(--boxel-lsp-xs);
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  min-height: var(--boxel-input-group-height);
}

.boxel-input-group .boxel-button--size-base { /* TODO: do this in a way that doesn't violate Boxel::Button */
  --boxel-button-min-height: var(--boxel-input-group-height);
}

.boxel-input-group__form-control {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-clip: padding-box;
  background-color: var(--boxel-light);
  color: var(--boxel-text-dark);
  display: block;
  flex: 1 1 auto;
  font-size: var(--boxel-font-size);
  font-weight: 400;
  line-height: var(--boxel-ratio);
  margin: 0;
  min-width: 0;
  padding: var(--boxel-input-group-padding-y) var(--boxel-input-group-padding-x);
  position: relative;
  width: 1%;
}

.boxel-input-group__form-control,
.boxel-input-group__accessory {
  border: 1px solid var(--boxel-input-group-border-color);
  border-radius: var(--boxel-input-group-border-radius);
  transition: border-color var(--boxel-transition);
  margin: 0;
  min-height: var(--boxel-input-group-height);
  outline-offset: 0;
}

.boxel-input-group__text-accessory {
  align-items: center;
  background-color: var(--boxel-light-400);
  color: var(--boxel-purple-900);
  display: flex;
  font-size: var(--boxel-font-size-sm);
  line-height: var(--boxel-ratio);
  padding: var(--boxel-input-group-padding-y) var(--boxel-input-group-padding-x);
  text-align: center;
  white-space: nowrap;
}

.boxel-input-group__button-accessory,
.boxel-input-group__icon-button-accessory,
.boxel-input-group__select-accessory {
  z-index: 2;
}

.boxel-input-group__select-accessory .boxel-select,
.boxel-input-group__select-accessory .boxel-select--selected .boxel-select__item {
  display: inline-block;
  font: var(--boxel-button-font, var(--boxel-font-sm));
  font-weight: 600;
  padding: var(--boxel-button-padding, var(--boxel-sp-xs) var(--boxel-sp-sm));
}

.boxel-input-group__select-accessory .boxel-select .ember-power-select-placeholder {
  font: var(--boxel-button-font, var(--boxel-font-sm));
  font-weight: 600;
}

.boxel-input-group__select-accessory .boxel-select--selected .boxel-select__item {
  padding: 0;
}

.boxel-input-group__select-accessory .ember-power-select-status-icon {
  background: url("/@cardstack/boxel/images/icons/caret-down.svg") no-repeat;
  width: 11px;
  height: 9px;
  display: inline-block;
  margin-left: var(--boxel-sp-xs);
}

.boxel-input-group__select-accessory [aria-expanded="true"] .ember-power-select-status-icon {
  transform: rotate(180deg);
}

.boxel-input-group__button-accessory:focus {
  z-index: 5;
}

.boxel-input-group--disabled .boxel-input-group__form-control,
.boxel-input-group--disabled .boxel-input-group__text-accessory,
.boxel-input-group--disabled .boxel-input-group__icon-button-accessory,
.boxel-input-group--disabled .boxel-input-group__button-accessory,
.boxel-input-group--disabled .boxel-input-group__select-accessory {
  border-color: var(--boxel-input-group-border-color);
  color: rgb(0 0 0 / 50%);
  opacity: 0.5;
}

.boxel-input-group--invalid .boxel-input-group__form-control,
.boxel-input-group--invalid .boxel-input-group__text-accessory,
.boxel-input-group--invalid .boxel-input-group__button-accessory,
.boxel-input-group--invalid .boxel-input-group__select-accessory {
  border-color: var(--boxel-error-100);
}

.boxel-input-group > :not(:first-child) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left-width: var(--boxel-input-group-interior-border-width);
}

.boxel-input-group > :not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-width: var(--boxel-input-group-interior-border-width);
}

.boxel-input-group__form-control:disabled {
  background-color: var(--boxel-light);
  color: rgb(0 0 0 / 50%);
}

.boxel-input-group__helper-text {
  grid-column: 2;
  margin-top: var(--boxel-sp-xs);
  margin-left: var(--boxel-sp-xs);
  color: rgb(0 0 0 / 75%);
  font: var(--boxel-font-sm);
  letter-spacing: var(--boxel-lsp);
}

.boxel-input-group__error-message {
  grid-column: 2;
  margin-top: var(--boxel-sp-xs);
  margin-left: var(--boxel-sp-xs);
  color: var(--boxel-error-100);
  font: var(--boxel-font-sm);
  letter-spacing: var(--boxel-lsp);
}

.boxel-input-group--invalid:focus-within .boxel-input-group__form-control {
  outline: 1px solid transparent;  /* Make sure that we make the invalid state visible */
  box-shadow: 0 0 0 1.5px var(--boxel-error-100);
}

.boxel-input-group--invalid:hover:not(.boxel-input-group--disabled) .boxel-input-group__form-control,
.boxel-input-group--invalid:hover:not(.boxel-input-group--disabled) .boxel-input-group__text-accessory,
.boxel-input-group--invalid:hover:not(.boxel-input-group--disabled) .boxel-input-group__button-accessory {
  border-color: var(--boxel-error-100);
}

.boxel-input-group--invalid:hover:not(.boxel-input-group--disabled) .boxel-input-group__form-control {
  border-color: var(--boxel-error-100);
  box-shadow: 0 0 0 1px var(--boxel-error-100);
}

.boxel-input-group--disabled ~ .boxel-input-group__error-message,
.boxel-input-group--disabled ~ .boxel-input-group__helper-text {
  display: none;
}
